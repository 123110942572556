export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0,3,5];

export const dictionary = {
		"/(app)/(homepage)": [~11,[3]],
		"/(app)/explore": [~12,[3]],
		"/(app)/feedback": [13,[3]],
		"/(app)/following": [~14,[3]],
		"/(app)/live": [~15,[3]],
		"/(alternate)/(auth)/login": [~7,[2]],
		"/(app)/post": [~16,[3]],
		"/(app)/post/[id]": [~17,[3],[,4]],
		"/(alternate)/privacy": [9,[2]],
		"/(app)/profile": [~18,[3]],
		"/(alternate)/(auth)/register": [~8,[2]],
		"/(app)/settings/(profile)": [~19,[3,5]],
		"/(app)/settings/appearance": [~20,[3,5]],
		"/(app)/settings/billing": [~21,[3,5]],
		"/(app)/settings/notifications": [~22,[3,5]],
		"/(app)/settings/tiers": [~23,[3,5]],
		"/(app)/settings/tiers/[id]": [~24,[3,5]],
		"/(app)/support": [25,[3]],
		"/(alternate)/terms": [10,[2]],
		"/(app)/user": [~26,[3]],
		"/(app)/user/[id]": [~27,[3],[,6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';